import * as React from "react";

import Seo from "components/seo";
import LayoutBanner from "../../templates/layoutBanner";
import Solution from "../../organisms/service/solution";
import { SERVICE_SOLUTION_TITLE, SERVICE_BULKSEND, SERVICE_GROUP, SERVICE_API, SERVICE_LINKSEND, SERVICE_DRAFT } from "constants/data/service_solution";
import { SERVICE_SOLUTION_META, SERVICE_SOLUTION_META_EN } from "../../constants/data/meta_data";
import { graphql } from "gatsby";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";

const SolutionPage = () => {
    const { i18n } = useTranslation();

    const seoData = useMemo(() => {
        if (i18n.language === "en") return SERVICE_SOLUTION_META_EN;

        return SERVICE_SOLUTION_META;
    }, [i18n.language]);

    return (
        <LayoutBanner>
            <Seo title={seoData.title} description={seoData.description} canonical={seoData.canonical} kakaoKeyword={seoData.kakao} />
            <Solution title={SERVICE_SOLUTION_TITLE} linksend={SERVICE_LINKSEND} bulksend={SERVICE_BULKSEND} group={SERVICE_GROUP} draft={SERVICE_DRAFT} api={SERVICE_API} />
        </LayoutBanner>
    );
};

export default SolutionPage;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
