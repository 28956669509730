// import { IC_GROUP_DOCUCHECK, IC_GROUP_DOCUCHECK_AVTICE, IC_GROUP_LIST, IC_GROUP_LIST_ACTIVE, IC_GROUP_UPLOAD, IC_GROUP_UPLOAD_ACTIVE } from "../media/icon";
// import {
//     IMG_SOLUTION_API,
//     IMG_SOLUTION_BULK_SEND,
//     IMG_SOLUTION_GROUP_ADD,
//     IMG_SOLUTION_GROUP_CONTRACT,
//     IMG_SOLUTION_GROUP_CONTRACT_M,
//     IMG_SOLUTION_GROUP_MANAGE,
//     IMG_SOLUTION_LINK_SEND,
//     IMG_SOLUTION_LINK_SEND_MOBILE
// } from "../media/images";

// import SOLUTION_MP4 from "images/video/video_landing_function_2.mp4";
// import SOLUTION_OGV from "images/video/video_landing_function_2.ogv";
// import SOLUTION_WEBM from "images/video/video_landing_function_2.webm";

// export const SERVICE_SOLUTION_TITLE = {
//     title: "비용은 줄이고,\n업무 효율은 높이고",
//     desc: "기업 규모에 따른 최적화 된 기능을 제공합니다.",
//     video: {
//         mp4: SOLUTION_MP4,
//         ogv: SOLUTION_OGV,
//         webm: SOLUTION_WEBM
//     }
// };

// export const SERVICE_BULKSEND = {
//     img: IMG_SOLUTION_BULK_SEND,
//     title: "대량전송으로\n수천명의 서명자에게\n원클릭 계약 발송",
//     desc: "근로계약서,동의서,확인서 등\n하나의 계약서로 여러명의 정보와 서명이 필요한 경우\n한번의 클릭으로 동시에 전송이 가능합니다.\n\n도뉴의 대량전송 양식에 맞춰 필수 정보를 입력 후\n양식과 계약서 업로드만 하면 끝.",
//     desc_mobile: "근로계약서,동의서,확인서 등\n하나의 계약서로 여러명의 정보와\n서명이 필요한 경우\n한번의 클릭으로 동시에 전송이 가능합니다.\n\n도뉴의 대량전송 양식에 맞춰 필수 정보를 입력 후\n양식과 계약서 업로드만 하면 끝."
// };

// export const SERVICE_LINKSEND = {
//     img: IMG_SOLUTION_LINK_SEND,
//     img_mobile: IMG_SOLUTION_LINK_SEND_MOBILE,
//     title: "상대방 연락처를 몰라도\n계약서 전송이 가능한\n링크 계약",
//     desc: "다수의 인원에게 계약서를 공유해야 하는데 \n 상대방의 연락처를 몰라 보낼 수 없었다면, \n이제 링크 계약을 통해 해결하세요. \n\n  URL, QR코드를 통해 쉽고 간편하게 계약서를 \n 공유하고 서명을 완료할 수 있습니다.",
//     desc_mobile: "다수의 인원에게 계약서를 공유해야 하는데 \n 상대방의 연락처를 몰라 보낼 수 없었다면, \n 이제 링크 계약을 통해 해결하세요. \n\n URL, QR코드를 통해 쉽고 간편하게 계약서를 \n 공유하고 서명을 완료할 수 있습니다."
// };

// export const SERVICE_API = {
//     img: IMG_SOLUTION_API,
//     title: "구축 비용은 아끼고\n필요한 계약 기능만!\n도뉴 API",
//     desc: "도뉴의 모든 기능들을 분리하여\n기업에서 필요한 기능만 연동하여 사용할 수 있습니다.\n불필요한 비용을 절약하고 합리적인 가격으로\n도뉴 API를 사용해 보세요.",
//     desc_mobile: "도뉴의 모든 기능들을 분리하여\n기업에서 필요한 기능만 연동하여\n사용할 수 있습니다.\n불필요한 비용을 절약하고 합리적인 가격으로\n도뉴 API를 사용해 보세요.",
//     link: {
//         type: "link",
//         title: "API 테스트 문의하기",
//         url: "채널톡"
//     }
// };

// export const SERVICE_GROUP = {
//     tab: [
//         {
//             title: "그룹원\n초대",
//             icon: IC_GROUP_UPLOAD,
//             icon_act: IC_GROUP_UPLOAD_ACTIVE
//         },
//         {
//             title: "그룹별\n관리",
//             icon: IC_GROUP_LIST,
//             icon_act: IC_GROUP_LIST_ACTIVE
//         },
//         {
//             title: "그룹원\n계약 확인",
//             icon: IC_GROUP_DOCUCHECK,
//             icon_act: IC_GROUP_DOCUCHECK_AVTICE
//         }
//     ],
//     items: [
//         {
//             img: IMG_SOLUTION_GROUP_ADD,
//             title: "간편한\n그룹원 초대",
//             desc: "관리자는 복잡한 절차없이\n비교적 쉽게 그룹원을 초대할 수 있습니다.\n\n초대된 그룹원이 가입할 경우,\n관리자가 결제한 요금제로 함께 적용됩니다.\n\n초대 가능한 그룹원은 요금제에 따라 상이합니다.",
//             link: {
//                 type: "path",
//                 title: "요금제 확인하기",
//                 url: "/pricing"
//             }
//         },
//         {
//             img: IMG_SOLUTION_GROUP_MANAGE,
//             title: "관리자 계정으로\n체계적인 그룹 관리",
//             desc: "관리자는 계약에 참여해야하는\n기업 내 팀 혹은 그룹을 생성하고\n각 그룹 내 그룹원들을 초대하여 관리할 수 있습니다.\n\n각 그룹원이 생성/체결한 모든 계약 정보를\n한눈에 확인할 수 있는 장점이 있습니다.",
//             desc_mobile: "관리자는 계약에 참여해야하는\n기업 내 팀 혹은 그룹을 생성하고\n각 그룹 내 그룹원들을 초대하여\n관리할 수 있습니다.\n\n각 그룹원이 생성/체결한 모든 계약 정보를\n한눈에 확인할 수 있는 장점이 있습니다."
//         },
//         {
//             img: IMG_SOLUTION_GROUP_CONTRACT,
//             img_mobile: IMG_SOLUTION_GROUP_CONTRACT_M,
//             title: "실시간 관리가 가능한\n그룹원 계약 현황",
//             desc: "관리자는 각 그룹과 그룹원이 진행한 모든 계약을\n도뉴의 관리자 계정으로 확인할 수 있습니다.\n\n그룹원 계약 현황은 오로지 관리자만 확인 가능하며\n그룹원끼리의 계약 상태나 문서는 공유되지 않습니다.\n\n관리되는 모든 문서는 일괄적으로 다운로드 하실 수 있습니다.",
//             desc_mobile:
//                 "관리자는 각 그룹과 그룹원이 진행한 모든 계약을\n도뉴의 관리자 계정으로 확인할 수 있습니다.\n\n그룹원 계약 현황은 오로지 관리자만 확인 가능하며\n그룹원끼리의 계약 상태나 문서는\n공유되지 않습니다.\n\n관리되는 모든 문서는 일괄적으로\n다운로드 하실 수 있습니다."
//         }
//     ]
// };

import { IC_GROUP_DOCUCHECK, IC_GROUP_DOCUCHECK_AVTICE, IC_GROUP_LIST, IC_GROUP_LIST_ACTIVE, IC_GROUP_MANAGE_MEMBER, IC_GROUP_MANAGE_MEMBER_ACTIVE, IC_GROUP_UPLOAD, IC_GROUP_UPLOAD_ACTIVE } from "../media/icon";
import {
    IMG_SOLUTION_API,
    IMG_SOLUTION_BULK_SEND,
    IMG_SOLUTION_DRAFT,
    IMG_SOLUTION_GROUP_ADD,
    IMG_SOLUTION_GROUP_CONTRACT,
    IMG_SOLUTION_GROUP_CONTRACT_M,
    IMG_SOLUTION_GROUP_MANAGE,
    IMG_SOLUTION_LINK_SEND,
    IMG_SOLUTION_LINK_SEND_MOBILE
} from "../media/images";

import SOLUTION_MP4 from "images/video/video_landing_function_2.mp4";
import SOLUTION_OGV from "images/video/video_landing_function_2.ogv";
import SOLUTION_WEBM from "images/video/video_landing_function_2.webm";

import SOLUTION_MP4_EN from "images/video/video_landing_function_2_en.mp4";
import SOLUTION_OGV_EN from "images/video/video_landing_function_2_en.mp4";
import SOLUTION_WEBM_EN from "images/video/video_landing_function_2_en.mp4";

export const SERVICE_SOLUTION_TITLE = {
    title: "SERVICE_SOLUTION_TITLE_TITLE",
    title_mobile: "SERVICE_SOLUTION_TITLE_TITLE_MOBILE",
    desc: "SERVICE_SOLUTION_TITLE_DESC",
    desc_mobile: "SERVICE_SOLUTION_TITLE_DESC_MOBILE",
    video: {
        ko: {
            mp4: SOLUTION_MP4,
            ogv: SOLUTION_OGV,
            webm: SOLUTION_WEBM
        },
        en: { mp4: SOLUTION_MP4_EN, ogv: SOLUTION_OGV_EN, webm: SOLUTION_WEBM_EN }
    }
};

export const SERVICE_BULKSEND = {
    img: IMG_SOLUTION_BULK_SEND,
    title: "SERVICE_BULKSEND_TITLE",
    desc: "SERVICE_BULKSEND_DESC",
    desc_mobile: "SERVICE_BULKSEND_DESC_MOBILE"
};

export const SERVICE_LINKSEND = {
    img: IMG_SOLUTION_LINK_SEND,
    img_mobile: IMG_SOLUTION_LINK_SEND_MOBILE,
    title: "SERVICE_LINKSEND_TITLE",
    desc: "SERVICE_LINKSEND_DESC",
    desc_mobile: "SERVICE_LINKSEND_DESC_MOBILE"
};

export const SERVICE_API = {
    img: IMG_SOLUTION_API,
    title: "SERVICE_API_TITLE",
    title_mobile: "SERVICE_API_TITLE_MOBILE",
    desc: "SERVICE_API_DESC",
    desc_mobile: "SERVICE_API_DESC_MOBILE",
    link: {
        type: "link",
        title: "SERVICE_API_LINK_TITLE",
        url: "채널톡"
    }
};

export const SERVICE_DRAFT = {
    img: IMG_SOLUTION_DRAFT,
    title: "SERVICE_DRAFT_TITLE",
    desc: "SERVICE_DRAFT_DESC",
    desc_mobile: "SERVICE_DRAFT_DESC_MOBILE"
};

export const SERVICE_GROUP = {
    tab: [
        {
            title: "SERVICE_GROUP_TAB_TITLE_1",
            title_mobile: "SERVICE_GROUP_TAB_MOBILE_TITLE_1",
            icon: IC_GROUP_UPLOAD,
            icon_act: IC_GROUP_UPLOAD_ACTIVE
        },
        {
            title: "SERVICE_GROUP_TAB_TITLE_2",
            title_mobile: "SERVICE_GROUP_TAB_MOBILE_TITLE_2",
            icon: IC_GROUP_LIST,
            icon_act: IC_GROUP_LIST_ACTIVE
        },
        {
            title: "SERVICE_GROUP_TAB_TITLE_3",
            title_mobile: "SERVICE_GROUP_TAB_MOBILE_TITLE_3",
            icon: IC_GROUP_MANAGE_MEMBER,
            icon_act: IC_GROUP_MANAGE_MEMBER_ACTIVE
        }
    ],
    items: [
        {
            img: IMG_SOLUTION_GROUP_ADD,
            title: "SERVICE_GROUP_ITEM_1_TITLE",
            desc: "SERVICE_GROUP_ITEM_1_DESC",
            desc_mobile: "SERVICE_GROUP_ITEM_1_DESC_MOBILE",
            link: {
                type: "path",
                title: "SERVICE_GROUP_ITEM_1_LINK_TITLE",
                url: "/pricing"
            }
        },
        {
            img: IMG_SOLUTION_GROUP_MANAGE,
            title: "SERVICE_GROUP_ITEM_2_TITLE",
            desc: "SERVICE_GROUP_ITEM_2_DESC",
            desc_mobile: "SERVICE_GROUP_ITEM_2_DESC_MOBILE"
        },
        {
            img: IMG_SOLUTION_GROUP_CONTRACT,
            img_mobile: IMG_SOLUTION_GROUP_CONTRACT_M,
            title: "SERVICE_GROUP_ITEM_3_TITLE",
            desc: "SERVICE_GROUP_ITEM_3_DESC",
            desc_mobile: "SERVICE_GROUP_ITEM_3_DESC_MOBILE"
        }
    ]
};
