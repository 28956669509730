import React from "react";
import { TabButtonBox, TabText, TabIconWrapper } from "./style";
import Image from "../image/image";
import { useTranslation } from "react-i18next";

const TabButton = ({ data, active }) => {
    const { t } = useTranslation();

    return (
        <TabButtonBox active={active}>
            <TabIconWrapper>
                <Image filename={active ? data.icon_act : data.icon} alt={t(data.title)} />
            </TabIconWrapper>
            <TabText active={active}>{t(data.title)}</TabText>
        </TabButtonBox>
    );
};

export default TabButton;
