import React, { useState } from "react";
import ResponsiveMedia from "utils/responsive_media";

import { DescBox, DescWrapper } from "./style";
import { Container, Box } from "components/style/layoutStyle";
import { TextBold, TextNormal } from "components/style/textStyle";
import TextButton from "components/button/textButton";
import Image from "components/image/image";
import TextTab from "../textTab";
import { useTranslation } from "react-i18next";

const DescriptionGroupTab = ({ data, bgColor }) => {
    const { t } = useTranslation();
    const { isMobile } = ResponsiveMedia();
    const [currentItem, setCurrentItem] = useState(data.items ? data.items[0] : { desc: data.desc, img: data.img });

    const handleCurrentItem = (index) => {
        setCurrentItem(data.items[index]);
    };

    const Desc = (props) => {
        const descData = props.data;

        const title = descData.title;
        const desc = isMobile === "true" && descData.desc_mobile ? descData.desc_mobile : descData.desc;
        const img = isMobile === "true" && descData.img_mobile ? descData.img_mobile : descData.img;

        return (
            <DescBox bgColor={"#f9f9f9"} padding={"60px 0 0"}>
                <DescWrapper>
                    <TextBold type={"26"} textAlign={"center"} margin={"0 0 32px"}>
                        {t(title)}
                    </TextBold>
                    <TextNormal type={"16"} color={"#1d1d1d"} textAlign={"center"} margin={"0 0 20px"}>
                        {t(desc)}
                    </TextNormal>
                    {!data.items && data.link && <TextButton margin={"0 0 40px"} data={data.link} />}
                    <Image filename={img} alt={desc} style={{ width: "100%", height: "auto", maxWidth: "700px" }} />
                </DescWrapper>
            </DescBox>
        );
    };

    return (
        <Container bgColor={bgColor} padding={"80px 0 60px"} flexDirection={"column"}>
            {data.tab && (
                <>
                    <Box justifycontent={"center"}>
                        <TextTab data={data.tab} margin={"0"} onClickEvent={handleCurrentItem} />
                    </Box>
                </>
            )}
            <Desc data={currentItem} />
        </Container>
    );
};

export default DescriptionGroupTab;
