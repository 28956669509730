import React from "react";
import ResponsiveMedia from "utils/responsive_media";
import { useState } from "react";
import TabButton from "../../../components/button/tabButton";
import { Box, Container } from "../../../components/style/layoutStyle";
import DescriptionWidhImage from "../../parts/description/descriptionWithImage";
import DescriptionGroupTab from "../../parts/description/descriptionGroupTab";

const Group = ({ data }) => {
    const { isMobile } = ResponsiveMedia();
    const [active, setActive] = useState(0);

    const handleTab = (index) => {
        setActive(index);
    };

    return (
        <>
            {isMobile === "true" ? (
                <DescriptionGroupTab data={data} />
            ) : (
                <Container bgColor={"#f8f8f8"} padding={"90px 40px 60px"}>
                    <Box flexDirection={"column"}>
                        <Box width={"815px"} justifycontent={"space-between"}>
                            {data.tab.map((tab, index) => {
                                return (
                                    <div key={index} onClick={() => handleTab(index)}>
                                        <TabButton key={index} data={tab} active={active === index} />
                                    </div>
                                );
                            })}
                        </Box>
                        {data.items.map((desc, index) => {
                            return <DescriptionWidhImage key={index} data={desc} padding={"80px 0 0"} imgWidth={"664px"} display={active === index ? "flex" : "none"} reverse textToTop />;
                        })}
                    </Box>
                </Container>
            )}
        </>
    );
};

export default Group;
